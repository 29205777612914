import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Cards from '@molecules/cards'
import Text from '@particles/text'
import SEO from '@atoms/seo'
import SubNav from '@molecules/sub-nav'
import Dropdown from '@molecules/dropdown'
import { InstantSearch, connectRefinementList, connectHits, Configure, connectPagination, connectToggleRefinement } from 'react-instantsearch-core'
import algoliasearch from 'algoliasearch/lite'
import Icon from '@icons'
import Pagination from '@atoms/pagination'
import filterHtmlToText from "/src/utils/html-to-text.js"
import Link from "@atoms/link"
import Toggle from '@atoms/toggle'
import {media} from '@breakpoints'
import { useCookies } from 'react-cookie'

const algoliaClient = algoliasearch(
  'U276KG3JBP',
  'cf30bf2b0d0511db02921358e65eeeaa',
  { _useRequestCache: true }
)

const filterItems = items => {
  return items.sort((a,b) => {
    if(a.label < b.label) { return -1; }
    if(a.label > b.label) { return 1; }
    return 0;
  })
}

const transformCategories = (items) => {
  let filter = filterItems(items)
  filter.unshift({label: "All metrics categories", value: [], suffix: false, isRefined: !items.some(item => item.isRefined)})
  return filter
}




const ToggleRefinement = ({currentRefinement, refine, icon, label, style}) => (
  <Toggle icon={icon} label={label} selected={currentRefinement} style={style} onClick={event => {
    event.preventDefault();
    refine(!currentRefinement);
  }}/>
)

ToggleRefinement.propTypes = {
  currentRefinement: PropTypes.bool,
  refine: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object
}

const CustomToggleRefinement = connectToggleRefinement(ToggleRefinement)

const PaginationComponent = ({currentRefinement, refine, nbPages}) => (
  <Pagination currentPage={currentRefinement} totalPages={nbPages} change={refine}/>
)

PaginationComponent.propTypes = {
  currentRefinement: PropTypes.array,
  nbPages: PropTypes.number,
  refine: PropTypes.func
}

const CustomPagination = connectPagination(PaginationComponent)

const CustomRefinement = connectRefinementList(Dropdown)



const CardHit = ({hit}) => {
  const hasIcons = hit.extra_instant || hit.extra_benchmark || hit.extra_expert
  return (
    <Cards.Metric
      header={hit.name}
      footer={
        <Layout.Row style={{margin: "0 -0.25rem"}}>
          {hit.extra_instant && <Icon card name={"metric"}/>}
          {hit.extra_benchmark && <Icon card name={"goal"}/>}
          {hit.extra_expert && <Icon card name={"user"}/>}

        </Layout.Row>
      }
      auto="true"
      hover="true"
      link={hit.url}
    >
      <Text lines={(hasIcons ? 4 : 5) - Math.floor((hit.name.length / 24))}>
        {hit.description?.trim()}
      </Text>
    </Cards.Metric>
  )
}

CardHit.propTypes = {
  hit: PropTypes.object
}

const Hits = ({hits}) => {
  return hits.length > 0 ? (
    <Layout.Grid fixed={294} gap={1.5} margin="T3.5 L0" rows={3} style={{minHeight: "738px"}} >
      {hits.map((hit) => {
        return <CardHit hit={hit} key={`${hit.name}`}/>
      })}
    </Layout.Grid>
  ) : (<Layout.Grid margin={"T3 L0"} justify={"center"} style={{minHeight: "738px"}} ><Text bold size={2}>No results for current refinement</Text></Layout.Grid>)
}

Hits.propTypes = {
  hits: PropTypes.array
}

const CustomHits = connectHits(Hits)

const sortOptions = [
  {label: "Alphabetically (A-Z)", value: "alphabetical_asc", icon: <Icon size={0.5} name={"arrow-down"}/>}, 
  {label: "Alphabetically (Z-A)", value: "alphabetical_desc", icon: <Icon size={0.5} name={"arrow-up"}/>}, 
  {label: "Newly Added", value: "created", icon: <Icon size={0.5} name={"plus"}/>},
  {label: "Last Updated", value: "updated", icon: <Icon size={0.5} name={"last-updated"}/>}
]

const PageLayout = styled(Layout)`

  ${media.desktop_small`
    .hide-filters {
      display: none;
    }
  `}

`

const AllMetricsFilter = ({ data, location }) => {

  const [selectedCategory, setSelectedCategory] = useState("")
  const [desc, setDesc] = useState("")
  const [searchState, setSearchState] = useState({})
  const [initSort, setInitSort] = useState(0)
  const [cookies, setCookie] = useCookies(['filters'])
  const [sort, setSort] = useState("alphabetical_asc")
  const categories = data.categories.edges
  const algolia_index = data.site.siteMetadata.algolia_index

  const searchStateChange = (searchState) => {
    setSearchState(searchState)
    setCookie("filters", searchState)
  }

  useEffect(() => {
    if(selectedCategory) {
      let find = categories.find(({node}) => {
        return node.name === selectedCategory
      })
      if(find) {
        setDesc(filterHtmlToText(find.node.description))
      } else {
        setDesc('Learn more about the metrics that matter the most to your business success')
      }
    } else {
      setDesc('Learn more about the metrics that matter the most to your business success')
    }
  }, [selectedCategory])

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let tempSearchParams = searchState;
    tempSearchParams.refinementList = {}
    tempSearchParams.toggle = {}
    const service = searchParams.get("service")
    const role = searchParams.get("role")
    const category = searchParams.get("category")
    const sort = searchParams.get("sort")
    const instant = searchParams.get("instant")
    const benchmark = searchParams.get("benchmark")
    const expert = searchParams.get("expert")
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
    window.history.replaceState({path: newurl}, '', newurl);
    let update = false

    

    if(service) {
      // setInitService(service)
      tempSearchParams.refinementList['services.name'] = [service]
      update = true
    }
    if(role) {
      // setInitRole(role)
      tempSearchParams.refinementList['roles.name'] = [role]
      update = true
    }
    if(category) {
      // setInitCategory(category)
      tempSearchParams.refinementList['categories.name'] = [category]
      setSelectedCategory(category)
      update = true
    }
    if(sort) {
      setInitSort(parseInt(sort))
    }
    if(instant) {
      // setInitInstant(true)
      tempSearchParams.toggle.extra_instant = true
      update = true
    }
    if(benchmark) {
      // setInitBenchmark(true)
      tempSearchParams.toggle.extra_benchmark = true
      update = true
    }
    if(expert) {
      // setInitExpert(true)
      tempSearchParams.toggle.extra_expert = true
      update = true
    }
    if(cookies['filters']) {
      if(update) {
        setCookie("filters", false)
      } else {
        tempSearchParams = cookies['filters']
        update = true
      }
    }
    if(update) {
      setSearchState({...tempSearchParams})
    }
    
  }, [location])
  return (
    <PageLayout location={location} subnav>
      <SEO title={`All Metrics - Filter`} location={location} />
      
      <InstantSearch indexName={`${algolia_index}${sort ? ("_" + sort.toLowerCase()) : ""}`} searchClient={algoliaClient} onSearchStateChange={searchStateChange} searchState={searchState}>
        <Configure filters={"type:metric"} hitsPerPage={12} attributesToRetrieve={[
          "name",
          "description",
          "url",
          "extra_benchmark",
          "extra_expert",
          "extra_instant",
        ]}/>
        <SubNav>
          <Layout.Row justify={"space-between"} style={{flex: 1}}>
            <Layout.Row justify={"flex-end"} >
              <CustomRefinement style={{marginRight: "1rem"}} limit={50} attribute="categories.name" facetOrdering={false} suffix={"Metrics"} transformItems={transformCategories} title={"Categories"} change={(cat) => setSelectedCategory(cat)}/>
              <CustomRefinement className={"hide-filters"} style={{marginRight: "1rem"}} limit={50} attribute="services.name" multiple facetOrdering={false}  transformItems={filterItems} title={"Services"} icon={<Icon name={"sort"} size={1}/>}/>
              <CustomRefinement className={"hide-filters"} style={{marginRight: "1rem"}} limit={50} attribute="roles.name" multiple facetOrdering={false}  transformItems={filterItems} title={"Roles"} icon={<Icon name={"sort"} size={1}/>}/>
              <Dropdown className={"hide-filters"} title={"Sort"} defaultClick={0} init={initSort} icon={<Icon name={"sort-list"} size={1}/>} options={sortOptions} change={(option) => {setSort(option?.value || "")}}/>
            </Layout.Row>
            <Layout.Row justify={"center"} className={"hide-filters"}>
              <CustomToggleRefinement style={{marginRight: "1rem"}} attribute={"extra_instant"} value={true} label={"Instant Metrics"} icon={"metric"}/>
              <CustomToggleRefinement style={{marginRight: "1rem"}} attribute={"extra_benchmark"} value={true} label={"Benchmark"} icon={"goal"}/>
              <CustomToggleRefinement attribute={"extra_expert"} value={true} label={"Expert Contributed"} icon={"user"}/>
            </Layout.Row>
          </Layout.Row>
        </SubNav>
        <Heading center h1 margin="T8 B1">
          {selectedCategory ? `${selectedCategory} Metrics` : 'All Metrics'}
        </Heading>
        <Text center size={1.25} style={{ maxWidth: '550px', margin: '0 auto' }}>
          {desc}
        </Text>
        <CustomHits />
        <Layout.Center margin={"T2"}>
          <CustomPagination/>
        </Layout.Center>
        <Layout.Center margin={"T1"}>
        <Heading h4 >Can&apos;t find what you are looking for?</Heading>
          <Link to={"contribute"} margin={"T0.25"}>Contribute a metric</Link>
        </Layout.Center>
      </InstantSearch>
    </PageLayout>
  )
}


AllMetricsFilter.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}
export default AllMetricsFilter

export const pageQuery = graphql`
  query AllMetricsFilterCategory {
    site {
      siteMetadata {
        algolia_index
      }
    }
    categories: allCategories {
      edges {
        node {
          name
          description
        }
      }
    }
  }
`
